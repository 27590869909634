import { useContext, useEffect, useState } from 'react';
import { Routes , Route, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './context/auth-context';
import { getUserDoc } from './firebase/firebase'
import Home from './routes/home';
import Signup from './routes/signup';
import Login from './routes/login';
import Profile from './routes/profile';
import ForgotPassword from './routes/forgotPassword';
import ResetPassword from './routes/resetPassword';
import Upload from './routes/upload';
import Race from './routes/race';
import ErrorPage from './components/ErrorPage';
import RequireAuth from './components/require-auth';
import IUser from './models/UserModel';
import "./App.css";

type UserUpload = {
    url: string,
    candidate: string,
    source: string,
    nonPolitician: boolean,
    userZip: string,
}

function App() {
    let location = useLocation();
    const defaultUser: IUser = {
      id: '',
      zip: '',
      email: '',
      state: '',
      upgraded: false,
    };
    const emptyRaceArray: string[] = [];
    const emptyUserUploadArray: UserUpload[] = [];
    const { currentUser } = useContext(AuthContext);
    const [userData, setUserData] = useState(defaultUser);
    const [watchedRaces, setWatchedRaces] = useState(emptyRaceArray);
    const [userUploads, setUserUploads] = useState(emptyUserUploadArray);
    const navigate = useNavigate();
    const updateUserData = (id: string, zip: string, email: string, state: string, upgraded: boolean):void => {
      setUserData({...userData, id, zip, email, state, upgraded});
    };
    const updateWatchedRaces = (races: string[]) => {
      setWatchedRaces(races);
    };
    const getUserInfo = async(userId: string) => {
      const userDoc = await getUserDoc(userId);
      setWatchedRaces(userDoc.watchedRaces);
      setUserUploads(userDoc.racesUploaded);
    };

    // Check if the current user exists on the initial render.
    useEffect(() => {
      if (location.pathname === '/signup' && location.search) {
        navigate(`${location.pathname + location.search}`);
      } else if (!userData.id && currentUser) {
        setUserData({...userData, id: currentUser.uid});
        getUserInfo(currentUser.uid);
      }
    }, [currentUser, navigate, userData, setUserData, location, setWatchedRaces]);
    
    return (
      <Routes>
        <Route index element={
          <RequireAuth>
            <Home user={userData} changeUser={updateUserData} watchedRaces={watchedRaces} updateWatchedRaces={updateWatchedRaces} />
          </RequireAuth>} 
        />
        <Route path="upload" element={
          <RequireAuth>
            <Upload user={userData} />
          </RequireAuth>} 
        />
        <Route path="race/:raceId" element={
          <RequireAuth>
            <Race user={userData} watchedRaces={watchedRaces} updateWatchedRaces={updateWatchedRaces} />
          </RequireAuth>} 
        />
        {/* New path we need, for user's profile page, roughly: */}
        <Route path="profile" element={
            <RequireAuth>
                <Profile user={userData} changeUser={updateUserData} watchedRaces={watchedRaces} userUploads={userUploads} />
            </RequireAuth>} 
        />
        <Route path="login" element={<Login changeUser={updateUserData} />} />
        <Route path="signup" element={<Signup changeUser={updateUserData} />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    )
}

export default App;
