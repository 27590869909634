type Candidate = {
    firstName: string;
    lastName: string;
    affiliation: string;
    incumbent: boolean;
    // suggestion for now: if it's a non-political campaign, use lastName as string for the info of the choice, firstName as empty string
}

type RaceType = {
    [index: string]: {
        id: string;
        race: string;
        candidates: Candidate[];
        year: string;
        location: string;
        zips: string[];
        notes: string[];
        winner: string;
    }
}

const raceData: RaceType = {
    "1": {
        "id": "1",
        "race": "Board of Education, At-Large Seat 9",
        "candidates": [{firstName: "Jessica", lastName: "Johnson", affiliation: "", incumbent: false}, {firstName: "Nkoyo", lastName: "Effiong Lewis", affiliation: "", incumbent: false}],
        "year": "2023",
        "location": "Atlanta, GA",
        "zips": ["30303", "30305", "30306", "30307", "30308", "30309", "30310", "30311", "30312", "30313", "30314", "30315", "30316", "30317", "30318", "30319", "30324", "30326", "30327", "30331", "30332", "30334", "30336", "30342", "30344", "30354", "30363"],
        "notes": [],
        "winner": "Undecided"
    },
    "2": {
        "id": "2",
        "race": "At-Large Council Seats",
        "candidates": [
            {
                firstName: "Robert",
                lastName: "Smith",
                affiliation: "",
                incumbent: true,
            },
            {
                firstName: "Rebecca",
                lastName: "Spitler",
                affiliation: "",
                incumbent: true,
            },
            {
                firstName: "Tammy",
                lastName: "LaPread",
                affiliation: "",
                incumbent: false,
            },
            {
                firstName: "Gary",
                lastName: "Volino",
                affiliation: "",
                incumbent: false,
            },
            {
                firstName: "Tameka",
                lastName: "Womack",
                affiliation: "",
                incumbent: false,
            }
        ],
        "year": "2023",
        "location": "Berkeley Lake, GA",
        "zips": ["30071", "30092", "30096"],
        "notes": ["2 seats", "Mayor is Lois Salter"],
        "winner": "Undecided"
    },
    "3": {
        "id": "3",
        "race": "Mayor",
        "candidates": [
            {
                firstName: "Mark",
                lastName: "Frost",
                affiliation: "",
                incumbent: false,
            },
            {
                firstName: "Lauren",
                lastName: "Kiefer",
                affiliation: "",
                incumbent: false,
            },
            {
                firstName: "Hilerie",
                lastName: "Lind",
                affiliation: "",
                incumbent: false,
            },
            {
                firstName: "John",
                lastName: "Park",
                affiliation: "",
                incumbent: false,
            }
        ],
        "year": "2023",
        "location": "Brookhaven, GA",
        "zips": ["30319", "30324", "30329", "30341", "30342", "31119"],
        "notes": [],
        "winner": "Undecided"
    },
    "4": {
        "id": "4",
        "race": "Mayor",
        "candidates": [
            {
                firstName: "Tyrisha",
                lastName: "Davis",
                affiliation: "",
                incumbent: false,
            },
            {
                firstName: "Kesha",
                lastName: "Gibson-Carter",
                affiliation: "",
                incumbent: false,
            },
            {
                firstName: "Van",
                lastName: "Johnson",
                affiliation: "",
                incumbent: true,
            }
        ],
        "year": "2023",
        "location": "Savannah, GA",
        "zips": ["31302", "31322", "31401", "31402", "31403", "31404", "31405", "31406", "31407", "31408", "31409", "31410", "31411", "31412", "31414", "31415", "31416", "31418", "31419", "31420", "31421"],
        "notes": [],
        "winner": "Undecided"
    },
    "5": {
        "id": "5",
        "race": "Mayor",
        "candidates": [
            {
                firstName: "Alex",
                lastName: "Bakry",
                affiliation: "",
                incumbent: false,
            },
            {
                firstName: "Ken",
                lastName: "Hymes",
                affiliation: "",
                incumbent: false,
            },
            {
                firstName: "Derek",
                lastName: "Norton",
                affiliation: "",
                incumbent: true,
            }
        ],
        "year": "2023",
        "location": "Smyrna, GA",
        "zips": ["30060", "30069", "30080", "30081", "30082", "30126", "30339"],
        "notes": [],
        "winner": "Undecided"
    }
};

export default raceData;