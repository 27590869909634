import { FormEvent, useState } from "react";
import { passwordReset } from "../firebase/firebase";
import '.././App.css';
import Nav from '../components/Nav';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [emailMessage, setEmailMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const setProperMessage = () => {
        if (emailMessage) {
            return <h2>Reset email sent!</h2>;
        } else if (errorMessage) {
            return (
                <div>
                    <h3>We're sorry, there's an error with the email input.</h3>
                    <div className="underline" onClick={resetForm}>Please try again.</div>
                </div>
            );
        }
    };

    const resetForm = () => {
        setEmail('');
        setEmailMessage(false);
        setErrorMessage(false);
    };
  
    const handleSubmit = async(evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        try {
            await passwordReset(email);
            setEmailMessage(true);
        } catch (error: any) {
            setErrorMessage(true);
            if (error.code === 'auth/user-not-found') {
                setEmail('');
                console.log("no user with that email was found; do some error messaging.");
            }
        }
    };

    return (
        <div className="App">
            <Nav />
            <div className="container full-section">
                { emailMessage || errorMessage ?
                setProperMessage() :
                <div className="row">
                    <h2>Reset your password here</h2>
                    <form onSubmit={handleSubmit}>
                        <input type="email" name="email" placeholder="email@email.com" onChange={(e) => setEmail(e.target.value)} required />
                        <div className="pad-top">
                            <button type="submit">Reset Password</button>
                        </div>
                    </form>
                </div>
                }
            </div>
        </div>
    );
};
 
export default ForgotPassword;