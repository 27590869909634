import {useState} from "react";
import "../App.css";
 
function NewsCard(props: {date: string, headline: string, img: string, text: string}) {
    // const [file, setFile] = useState<File | undefined>();
    // const [percent, setPercent] = useState(0);
 
    return (
        <div className="container">
            <div className="row image"><img src={props.img} className="news-img" alt="card" /></div>
            <div className="row date">{props.date}</div>
            <div className="row headline">{props.headline}</div>
            <div className="row card-text">{props.text}</div>
            {/* <div className="row learn-more"><em>Learn More</em></div> */}
        </div>
    );
};
 
export default NewsCard;