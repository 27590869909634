import { Link } from 'react-router-dom';

type RaceLinkProps = {
    cand1?: string;
    cand2?: string;
    race?: string;
    raceId: string;
    location: string;
    color: string;
}
 
const RaceLink = (props: RaceLinkProps) => {
    const { cand1, cand2, race, raceId, location, color } = props;
    const linkToRace = `/race/${raceId}`;
 
    // eventually, need functionality to make a search work, but we don't have that kind of data yet

    return (
        <div className="container race-link">
            <Link to={linkToRace}>
                <div className="row full-height-in-div">
                    <div className="col-2 color">
                        <span className={color}></span>
                    </div>
                    <div className="col-8 candidates">
                        {(cand1 && cand2)?`${cand1} v ${cand2}`:`${race}`}
                    </div>
                    <div className="col-2 loc">
                        {location}
                    </div>
                </div>
            </Link>
        </div>
    );
};
 
export default RaceLink;