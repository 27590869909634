import Nav from "./Nav";
import Footer from "./Footer";

const ErrorPage = () => {

    return(
        <div className="App">
          <Nav />
          <div>
            <h1>404!</h1>
            <p>Sorry, we couldn't find that page.</p>
          </div>
          <Footer />
        </div>
    )
};

export default ErrorPage;