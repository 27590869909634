import { ChangeEvent, FormEvent, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { AuthContext } from '../context/auth-context';
import { registerUser, addUserToDB } from '../firebase/firebase';
import Nav from '../components/Nav';
import NewsCard from '../components/NewsCard';
import ImgLogin1 from '../img/img-cor-login1.svg';
import ImgLogin2 from '../img/img-cor-login2.svg';
import ImgLogin3 from '../img/img-cor-login3.svg';


const defaultFormFields = {
    email: '',
    password: '',
    zip: '',
};

const states = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN",
"MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "VI", "WA", "WV",
"WI", "WY"];

type SignUpProps = {
  changeUser: (id: string, zip: string, email: string, state: string, upgraded: boolean) => void;
};

// function SignUp({changeUser}) {
const SignUp = (props: SignUpProps) => {
    const location = useLocation();
    const { changeUser } = props;
    const [formFields, setFormFields] = useState(defaultFormFields);
    const [emailParam, setEmailParam] = useState("");
    const [userState, setUserState] = useState("");
    const [tosChecked, setTosChecked] = useState(false);
    const [emailUpdates, setEmailUpdates] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [emailIsValid, setEmailIsValid] = useState(true);
    const { password, zip } = formFields;
    let { email } = formFields;
    if (emailParam) {
      email = emailParam;
    }
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    // Get the email from the query string
    useEffect(() => {
      if (location.search) {
        let searchParts = location.search.split('='); // should just be one param, with one part before the = and one part after
        if (searchParts.length < 3 && searchParts[0].includes('email')) {
          // expected condition, since we're not planning to have any other fields from the website form for now
          setEmailParam(searchParts[1]);
        }
      }
    }, [location, emailParam]);

    if (currentUser) {
      navigate('/');
    }

    const resetFormFields = () => {
      return (
        setFormFields(defaultFormFields)
      );
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!tosChecked) {
        setShowErrorMessage(true);
        setErrorMessage("You must agree to our terms of service to sign up!");
      } else if (tosChecked) {
        setShowErrorMessage(false);
        setErrorMessage("");
      }
      
      let zipInt = parseInt(zip);
      let zipAsIntDigits = zipInt.toString().split('').length;
      // perhaps overly cautious input error handling so we can give an accurate message
      if (!password) {
        setErrorMessage("Please enter a secure password.");
      } else if (!zip || zip.length !== 5) {
        setErrorMessage("You must enter a 5-digit ZIP code to sign up.");
      } else if (zip && zip.length === 5 && (Number.isNaN(zipInt) || zipAsIntDigits !== 5)) {
        setErrorMessage("Please only use numbers for the ZIP code.");
      } else if (!userState) {
        setErrorMessage("Please select a state.");
      } else {
        setErrorMessage("");
      }

      if (tosChecked && !errorMessage) {
        try {
          // Send the email and password to firebase
          const userCredential = await registerUser(email, password);
          // initiate defaults for some user data that we store in the database
          const upgraded = false;
          const watchedRaces: string[] = [];
  
          if (userCredential) {
            addUserToDB(userCredential.user.uid, zip, userState, email, emailUpdates, watchedRaces);
            changeUser(userCredential.user.uid, zip, email, userState, upgraded);
            resetFormFields();
            navigate('/');
          }
        } catch (error:any) {
          console.log(`User Sign Up Failed, code ${error.code}; message: ${error.message}`);
          setErrorMessage("User sign up failed. Please try again later or contact support.");
        }
      } else {
        event.preventDefault();
      }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormFields({...formFields, [name]: value });
      if (name === 'email') {
        if (isEmail(value)) {
          setEmailIsValid(true);
        } else {
          setEmailIsValid(false);
        }
      }
      if (errorMessage && errorMessage.includes("password") && name === 'password') {
        // get rid of error message when rectifying a password error
        setErrorMessage("");
      } else if (errorMessage && errorMessage.includes("ZIP") && name === 'zip') {
        // get rid of error message when rectifying a ZIP code error
        setErrorMessage("");
      } else if (errorMessage && errorMessage.includes("sign up failed")) {
        // get rid of error message when retrying after receiving the general sign up failure message, likely from Firebase and not us
        setErrorMessage("");
      }
    };

    const handleStateChange = (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      console.log("updated state value is ", value);
      setUserState(value);
    };

    const handleTosCheckChange = () => {
      setTosChecked(!tosChecked);
      if (showErrorMessage) {
        setShowErrorMessage(!showErrorMessage);
      }
    };

    const handleEmailUpdateChange = () => {
      setEmailUpdates(!emailUpdates);
    };

    const infoPage = '/info';
    const tosPage = '/tos';

    return(
      <div className="App">
        <Nav />
        <div className="container full-section auth-page">
          <div className="row full-height">
            <div className="col col-md-3 sign-in-div">
              <div className="signin">
                <h2 className="hero-font sign-in-headline">Sign Up</h2>
                <form onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row d-flex">
                      <div className="col-1"></div>
                      <div className="col-10 grid-display">
                        <label className="left-justify headline-sm" htmlFor="email">Email</label>
                      </div>
                      <div className="col-1"></div>
                    </div>
                    <div className="row d-flex input-space">
                      <div className="col-1"></div>
                      <div className="col-10 justify-content-start">
                        <input
                          type="email"
                          name="email"
                          value={email}
                          className="sign-in-input"
                          onChange={handleChange}
                          placeholder="example@email.com"
                          required
                        />
                      </div>
                      <div className="row d-flex justify-content-center email-message">
                        {(!emailIsValid)?"Invalid email format":null}
                      </div>
                      <div className="col-1"></div>
                    </div>
                    <div className="row d-flex">
                      <div className="col-1"></div>
                      <div className="col-10 grid-display">
                        <label className="left-justify headline-sm" htmlFor="password">Password</label>
                      </div>
                      <div className="col-1"></div>
                    </div>
                    <div className="row d-flex input-space">
                      <div className="col-1"></div>
                      <div className="col-10 justify-content-start">
                        <input
                          type='password'
                          name='password'
                          value={password}
                          className="sign-in-input"
                          onChange={handleChange}
                          placeholder="password"
                          required
                        />
                      </div>
                      <div className="col-1"></div>
                    </div>
                    <div className="row d-flex">
                      <div className="col-1"></div>
                      <div className="col-10 grid-display">
                        <label className="left-justify headline-sm" htmlFor="zip">State</label>
                      </div>
                      <div className="col-1"></div>
                    </div>
                    <div className="row d-flex input-space">
                      <div className="col-1"></div>
                      <div className="col-10">
                        <select name="userState" className="sign-in-input lable-sm select-styled" onChange={handleStateChange}>
                          <option value="">state</option>
                          {states.map((opt, index) => {
                            return (<option key={index} value={opt}>
                              {opt}
                            </option>)
                          })}
                        </select>
                      </div>
                      <div className="col-1"></div>
                    </div>
                    <div className="row d-flex">
                      <div className="col-1"></div>
                      <div className="col-10 grid-display">
                        <label className="left-justify headline-sm" htmlFor="zip">ZIP code</label>
                      </div>
                      <div className="col-1"></div>
                    </div>
                    <div className="row d-flex input-space">
                      <div className="col-1"></div>
                      <div className="col-10 justify-content-start">
                        <input
                          type='zip'
                          name='zip'
                          value={zip}
                          className="sign-in-input"
                          onChange={handleChange}
                          placeholder="ZIP code"
                          required
                        />
                      </div>
                      <div className="col-1"></div>
                      <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10 small-grey">
                          Why does my ZIP code need to be part of my account information? You can find out more info <Link className="underline" to={infoPage}>here</Link>  
                        </div>
                        <div className="col-1"></div>
                      </div>
                    </div>
                    <div className="row d-flex top-spacing">
                      <div className="col-2"></div>
                      <div className="col-1">
                        <input type="checkbox" id="tos" name="tos" value="tos" checked={tosChecked} onChange={handleTosCheckChange} />
                      </div>
                      <div className="col-8 small">I agree to the Courant <Link className="underline" to={tosPage}>Terms of Service</Link></div>
                    </div>
                    <div className="row d-flex">
                      <div className="col-2"></div>
                      <div className="col-1">
                        <input type="checkbox" id="email" name="email" value="email" checked={emailUpdates} onChange={handleEmailUpdateChange} />
                      </div>
                      <div className="col-8 small">I'd like to receive important updates about Courant and get the newsletter</div>
                    </div>
                    <div className="row d-flex">
                      <span className="red">
                        {(showErrorMessage)?"You must agree to the terms of service to sign up":null}
                      </span>
                    </div>
                    <div className="row d-flex">
                      <div className="col-1"></div>
                      <div className="col-10 sign-in-button-grp">
                        <input id='recaptcha' className="sign-in-button" type="submit" value="SIGN UP" />
                        { errorMessage && 
                        <div className="row sign-in-error">{errorMessage}</div> }
                      </div>
                      <div className="col-1"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-9 d-none d-md-flex justify-content-center sign-in-main">
              <div className="news justify-content-center">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <h2 className='rale-font txt-bold'>Our Latest News</h2>
                  </div>
                </div>
                <div className="row cards justify-content-center">
                         <div className="col-md-3 news-card mx-2">
                            <NewsCard date="Jan 2024" headline="Our MVP is up and running and ready for 2024!" img={ImgLogin2} text="We've merged our branches and have been successful in creating the base application! We'll be updating and working diligently to provide a robust experience for our Alpha build." />
                      </div>
                      <div className="col-md-3 news-card mx-2">
                        <NewsCard date="Feb 2024" headline="Courant application launch is officially live!" img={ImgLogin1} text="Courant has been able to streamline the uploading process and begin working on features such as admin portal, PII sanitation, and smooth UX." />
                      </div>
                      <div className="col-md-3 news-card mx-2">
                        <NewsCard date="Mar 2024" headline="Have a suggestion? You can contact us by email" img={ImgLogin3} text="Have a suggestion or a way to improve the Courant App? Drop us a line at hello@courant.com. Sign up for our mailing list at courantapp.com to get the latest news on future releases." />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default SignUp;