import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/auth-context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <HelmetProvider>
            <Helmet prioritizeSeoTags>
              <meta name="viewport" content="width=device-width, initial-scale=1" />
              <meta name="description" content="Courant is an application that allows you to share political campaign mailers to receive and compare them to the campaign mailers your neighbors receive." />
              <meta name="keywords" content="courant, politics, political, campaign, mailer, political campaign, politics campaign, political campaign mailer, politics campaign mailer, local politics" />
              <meta name="author" content="Courant" />
              <meta name="twitter:title" content="Courant- Home" />
              <meta name="twitter:description" content="Courant is an application that allows you to share and see political campaign mailers in your home area, and from around the country." />
              <meta name="twitter:site" content="@courant" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="og:title" content="Courant - Home" />
              <meta name="og:description" content="Courant is an application that allows you to share and see political campaign mailers in your home area, and from around the country." />
              <link rel="shortcut icon" href="/favicon.ico" />
              <link rel="stylesheet" href="https://fonts.cdnfonts.com/css/titania" />
              <link rel="stylesheet" href="https://fonts.cdnfonts.com/css/raleway-5" />
            </Helmet>
            <App />
          </HelmetProvider>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
