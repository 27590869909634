import { useState } from "react";
import { User } from "firebase/auth";

export const useLocalStorage = (key: string, defaultVal: User | null) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = window.localStorage.getItem(key);
            if (value) {
                return JSON.parse(value);
            } else {
                window.localStorage.setItem(key, JSON.stringify(defaultVal));
                return defaultVal;
            }
        } catch (err) {
            return defaultVal;
        }
    });
    const setValue = (newVal: User | null) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(newVal));
        } catch (err) {
            // do nothing with the error, I suppose
        }
        setStoredValue(newVal);
    };
    return [storedValue, setValue]
};