import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/auth-context';
import logo from '../img/courant-svg-logos/nav-courant.svg';
import "../App.css";

function Nav() {
    const { currentUser } = useContext(AuthContext);

    const website = "https://courantapp.com";
    const login = "/login";
    const home = "/";
    const upload = "/upload";
    const profile = "/profile";
    
    return(
        <header className="container-fluid">
            <nav className="row">
                <div className="col-6 nav-logo">
                    <div className="left-nav left-justify">
                        <Link className="h-link" to={home}><img className='img-logo' src={logo} alt="courant logo" /></Link>
                    </div>
                </div>
                <div className="col col-md-6 nav-container">
                    <div className="right-nav">
                        <input className="side-menu" type="checkbox" id="side-menu"/>
                        <label className="hamb" htmlFor="side-menu"><span className="hamb-line"></span></label>
                        <div className="nav">
                            <ul className="menu">
                                <li><a href={`${website}#about`} className="nav-item">about us</a></li>
                                <li><a href={`${website}#questions`} className="nav-item">help</a></li>
                                {currentUser && <li><Link className="link nav-item" to={upload}>upload</Link></li>}
                                {currentUser && <li className="d-md-none"><Link className="link nav-item" to={profile}>profile</Link></li>}
                                {!currentUser && <li><Link className="link nav-item" to={login}>sign in</Link></li>}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Nav;