// import {useState} from "react";
import { Link } from 'react-router-dom';
import UserModel from '../models/UserModel';
import RaceSummary from './RaceSummary';
// import { getRaceDocsByLocation } from '../firebase/firebase';

type RaceSummariesSectionProps = {
    user: UserModel;
    raceIds: number[];
    headline?: string;
    location: string;
    upgraded: boolean;
    lockedSummary: boolean;
}
 
const RaceSummariesSection = (props: RaceSummariesSectionProps) => {
    const { user, raceIds, headline, location, upgraded, lockedSummary } = props;
    const upgrade = '/404';
    // const currentRaces = getRaceDocsByLocation(location); // use this eventually to get races by the location and populate from there?
    const populateList = () => {
        const list = [];
        const colClass = `col-6 col-md-${Math.round(12/raceIds.length)}`;
        for (let i = 0; i < raceIds.length; i++) {
            list.push(<div className={colClass} key={i}>{makeRaceSummaryLink(raceIds[i])}</div>)
        }
        return <div className="row">{list}</div>
    };

    const makeRaceSummaryLink = (raceId: number) => {
        return <RaceSummary user={user} raceId={raceId} upgraded={upgraded} lockedSummary={lockedSummary} />
    };

    const containerClass = (headline)?"container summary-list":"col-xs-12 col-md-9 summary-list";

    const lockIcon = () => {
        return (<span className="upgrade">
                <span className="lock-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
                        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                    </svg>
                </span>
                <Link className="btn upgrade-btn" to={upgrade}>UPGRADE</Link>
            </span>)
    };

    const locationClass = () => {
        if (location.length === 2 || location.length === 5) {
            return "blue summary-location";
        } else {
            return "summary-location";
        }
    };
 
    return (
        <div className={containerClass}>
            {headline && <div className="row summary-headline-row"><div><span className="race-summary-headline">{headline}:</span>{(lockedSummary && !upgraded)?lockIcon():<span className={locationClass()}>{location}</span>}</div></div> }
            {populateList()}
        </div>
    );
};
 
export default RaceSummariesSection;