const config = {
    apiKey: "AIzaSyDPLmHTDmIN5WUbjgbX50AUPyswEA0wghs",
    authDomain: "courant-f5f75.firebaseapp.com",
    projectId: "courant-f5f75",
    storageBucket: "courant-f5f75.appspot.com",
    messagingSenderId: "351768202750",
    appId: "1:351768202750:web:8bc939528994eef8cdc4af",
    measurementId: "G-M4PN2YRT7B",
    databaseURL: "https://courant-f5f75-default-rtdb.firebaseio.com/",
};

export function getFirebaseConfig() {
    if (!config || !config.apiKey) {
        throw new Error('No Firebase configuration object provided.\n' +
        'Add your web app\'s configuration object to firebase-config.ts');
    } else {
        return config;
    }
}