import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { watchRace, unwatchRace, getUserDoc, getRaceUploadInfo } from '../firebase/firebase';
import raceData from '../data/raceData';
import '.././App.css';
import UserModel from '../models/UserModel';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import SearchBar from '../components/SearchBar';
import HowToUpload from '../components/HowToUpload';

type RaceProps = {
    user: UserModel;
    watchedRaces: string[];
    updateWatchedRaces: (races: string[]) => void;
}

const getWatchedRaces = async(id: string) => {
    const userDoc = await getUserDoc(id);
    let races = userDoc.watchedRaces.filter((race: string) => race !== '');
    return races;
};

type UploadObject = {
    url: string;
    source: string;
    candidate: string;
    nonPolitician: boolean;
    approved?: boolean;
    user?: string;
    userZip?: string;
}

const emptyUploadObj: UploadObject = {
    url: '',
    source: '',
    candidate: '',
    nonPolitician: false,
    approved: false,
    user: '',
    userZip: ''
};

const Race = (props: RaceProps) => {
    const { user, watchedRaces, updateWatchedRaces } = props;
    const [raceUploads, setRaceUploads] = useState([emptyUploadObj]);
    const [chosenImgIndex, setChosenImgIndex] = useState(0);
    const [chosenImg, setChosenImg] = useState(emptyUploadObj);
    const { raceId } = useParams();
    const currentZip = user.zip;
    const getRaceUploads = async(id: string) => {
        // param: race ID
        // update this to filter and only include approved images, or images that were uploaded by the current user; if they're not approved, add a note that it's pending
        let uploads = await getRaceUploadInfo(id);
        if (uploads && uploads.uploads && uploads.uploads.length > 0) {
            let filteredUploads = uploads.uploads.filter((upload: UploadObject) => upload.approved || upload.user === user.id);
            console.log("full uploads: ");
            console.log(uploads.uploads);
            console.log("and filtered uploads for only approved or uploads uploaded by me: ");
            console.log(filteredUploads);
            setRaceUploads(filteredUploads);
            setChosenImg(filteredUploads[0]);
        }
    };

    useEffect(() => {
        if (raceId) {
            getRaceUploads(raceId);
        }
    }, [raceId]);

    // dummy data, pulled from file
    const currentRaceData = (raceId)?raceData[raceId]:null;
    const dataForSelectedImgCandidate = (currentRaceData && chosenImg && chosenImg.candidate)?currentRaceData.candidates.filter((cand) => cand.lastName === chosenImg.candidate)[0]:null;

    const chooseImage = (e: React.MouseEvent<HTMLImageElement>, index: number) => {
        if (index !== chosenImgIndex) {
            setChosenImgIndex(index);
            setChosenImg(raceUploads[index]);
        }
    };

    const populateThumbs = () => {
        if (!raceUploads || raceUploads.length < 1) {
            return <div className="col-3 img-thumb-empty">Race has no uploads</div>
        } else {
            const list = [];
            for (let i = 0; i < raceUploads.length; i++) {
                list.push(<div className="col-3 img-thumb" key={i}><img src={raceUploads[i].url} onClick={(e) => chooseImage(e, i)} className="thumb" alt="race thumbnail" /></div>)
            }
            return list;
        }
    };

    const watchOrUnwatchRace = async() => {
        if (raceId && watchedRaces.includes(raceId)) {
            unwatchRace(user.id, raceId);
        } else if (raceId) {
            watchRace(user.id, raceId);
        }

        const races = await getWatchedRaces(user.id);
        updateWatchedRaces(races);
    };

    const watchUnwatchBtn = () => {
        let btnText;
        let btnClass;
        if (raceId && watchedRaces.includes(raceId)) {
            btnText = "UNWATCH";
            btnClass = "btn watch-btn dark-btn";
        } else {
            btnText = "WATCH";
            btnClass = "btn watch-btn light-btn";
        }
        return <div className={btnClass} onClick={watchOrUnwatchRace}>{btnText}</div>
    }

    return(
      <div className="App">
        <Nav />
        <SearchBar user={user} watchedRaces={watchedRaces} />
        <div className="container trending">
          <div className="row">
            <div className="row race-page-headline">{(currentRaceData)?`${currentRaceData.race} | ${currentRaceData.year}`:"Invalid race"}</div>
            <div className="row">
                <div className="col-8">{/* spacer */}</div>
                <div className="col-1">{watchUnwatchBtn()}</div>
            </div>
            <div className="row">
                <div className={`col-12 col-md-8 selected-img${(!raceUploads || raceUploads.length < 1)?' no-main-img':''}`}>{(chosenImg)?<img src={chosenImg.url} className="full-race-img" alt="selected upload from the race" />:<p>This race has no uploaded images yet.</p>}</div>
                <div className="col-12 col-md-3 race-details">
                    <div className="row bold pad-left">Details</div>
                    <div className="row pad-horz">
                        <div className="col-6 text-left underline-no-hover">{currentZip /* need the race zip instead? in race details table/api? */}</div>
                        <div className="col-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                            </svg>
                        </div>
                    </div>
                    <div className="row margin-horz race-name">{(currentRaceData)?`${currentRaceData.race}`:""}</div>
                    <div className="row margin-horz candidate-name">{(dataForSelectedImgCandidate)?dataForSelectedImgCandidate.lastName:""}</div>
                    <div className="row pad-horz">
                        <div className="col-2"><input type="checkbox" disabled checked={chosenImg && chosenImg.source === 'direct'}></input></div>
                        <div className="col-8 text-left">
                            <span className="detail-headline">Direct Mailer</span>
                            <p>Direct Mailers are mails that directly from an established campaign</p>
                        </div>
                    </div>
                    <div className="row pad-horz">Selection:</div>
                    <div className="row pad-horz">
                        <div className="col-3"><input type="checkbox" checked={(chosenImg && dataForSelectedImgCandidate && dataForSelectedImgCandidate.affiliation === 'D')?true:false}></input><span>(D)</span></div>
                        <div className="col-3"><input type="checkbox" checked={(chosenImg && dataForSelectedImgCandidate && dataForSelectedImgCandidate.affiliation === 'R')?true:false}></input><span>(R)</span></div>
                        <div className="col-3"><input type="checkbox" checked={(chosenImg && dataForSelectedImgCandidate && dataForSelectedImgCandidate.incumbent)?true:false}></input><span>(I)</span></div>
                        <div className="col-3"><input type="checkbox" checked={false}></input><span>(All)</span></div>
                    </div>
                    <div className="row upload-details">
                        <div className="col-12 pad-horz">
                            <div className="row detail-headline">Uploaded:</div>
                            <div className="row detail-data">{"October 8th, 2023"/* date, from mailer upload detail storage; for now, static string */}</div>
                            <div className="row detail-headline">Updated:</div>
                            <div className="row detail-data">{"October 24th, 2023"/* date, from mailer upload detail storage; for now, static string */}</div>
                            <div className="row detail-headline">Location:</div>
                            <div className="row detail-data">{(currentRaceData)?currentRaceData.location:""}</div>
                            <div className="row detail-headline">Winner:</div>
                            <div className="row detail-data">{(currentRaceData)?currentRaceData.winner:""}</div>
                        </div>
                    </div>
                    <div className="row add-btn-row">
                        <Link className="btn" to={"/upload"}>ADD MORE</Link>
                    </div>
                </div>
            </div>
            <div className="row img-thumbs">{populateThumbs()}</div>
          </div>
        </div>
        <HowToUpload />
        <Footer />
      </div>
    )
};

export default Race;