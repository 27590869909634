import { useState, useContext, useEffect, useCallback, ChangeEvent } from 'react';
import { AuthContext } from '../context/auth-context';
import { Link } from 'react-router-dom';
import { getUserDoc, submitAdminRequest, updatePassReq } from '../firebase/firebase';
import Swal from 'sweetalert2';
import '.././App.css';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import UserModel from '../models/UserModel';
import SearchBar from '../components/SearchBar';
import RaceSummariesSection from '../components/RaceSummariesSection';

type UserUpload = {
    url: string,
    candidate: string,
    source: string,
    nonPolitician: boolean,
    userZip: string,
}

type ProfileProps = {
    user: UserModel;
    changeUser: (id: string, zip: string, email: string, state: string, upgraded: boolean) => void;
    watchedRaces: string[];
    userUploads: UserUpload[];
}

const Profile = (props: ProfileProps) => {
    const { user, changeUser, watchedRaces, userUploads } = props;
    const { currentUser, signOut } = useContext(AuthContext);
    const [changePassOpen, setChangePassOpen] = useState(false);
    const [changeZipOpen, setChangeZipOpen] = useState(false);
    const [newPass, setNewPass] = useState("");
    const [newPassConfirm, setNewPassConfirm] = useState("");
    const [newZip, setNewZip] = useState("");
    const [passError, setPassError] = useState("");
    const [zipError, setZipError] = useState("");
    console.log("watchedRaces passed in: ", watchedRaces);
    const currentZip = user.zip;
    const userUpgraded = user.upgraded;
    const userUploadZips: string[] = [];
    userUploads.forEach((upload) => {
        // have to do this because the design has different rows for each ZIP, which is necessary because user's can request to change their zip
        if (!userUploadZips.includes(upload.userZip)) {
            userUploadZips.push(upload.userZip);
        }
    });
    // to build summaries, get uploads, filter by zip, and have arrays of them
    // and also get IDs of watched races for that, then pass those arrays to racesummarysections to build out in those components, basically
    const nationalRaceIDs = [1, 2, 3, 4, 5];
    const localRaceIDs = [1, 2, 3, 4, 5];
    const trendingIDs = [1, 3, 4, 5];

    const getUserAttributesAndUpdate = useCallback(async(user: UserModel) => {
      const userDoc = await getUserDoc(user.id);
      const zip = userDoc.zip;
      const status = userDoc.status;
      const email = userDoc.email;
      const userState = userDoc.state;
      changeUser(user.id, zip, email, userState, status);
    }, [changeUser]);

    const toggleChangePassword = () => {
        // trigger modal? have a form with 2 pass inputs, validate they match, and then submit? trigger sweet alert confirmation?
        setChangePassOpen(!changePassOpen);
    };

    const toggleChangeZip = () => {
        // like password, trigger modal? form for new zip, submit old zip and new zip
        setChangeZipOpen(!changeZipOpen);
    };

    const handleChangePass = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNewPass(value);
    };

    const handleChangePassConf = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNewPassConfirm(value);
    };

    const submitChangePass = () => {
        let passErr = false;
        if (!newPass || !newPassConfirm) {
            setPassError("Please enter values in both the New Password and New Password Confirmation fields!");
            passErr = true;
        } else if (newPass !== newPassConfirm) {
            setPassError("Password doesn't match!");
            passErr = true;
        } else {
            setPassError("");
            passErr = false;
        }

        if (!passErr) {
            updatePassReq(newPass);
            toggleChangePassword();
        }
    };

    const handleChangeZip = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNewZip(value);
    };

    const submitChangeZip = () => {
        let zipErr = false;
        let zipInt = parseInt(newZip);
        let zipAsIntDigits = zipInt.toString().split('').length;
        // perhaps overly cautious input error handling so we can give an accurate message
        if (!newZip || newZip.length !== 5) {
            setZipError("You must enter a 5-digit ZIP code to sign up.");
            zipErr = true;
        } else if (newZip && newZip.length === 5 && (Number.isNaN(zipInt) || zipAsIntDigits !== 5)) {
            setZipError("Please only use numbers for the ZIP code. Must be 5 digits.");
            zipErr = true;
        } else {
            setZipError("");
            zipErr = false;
        }

        if (!zipErr) {
            submitAdminRequest('change user ZIP code', user.email, currentZip, newZip).then((res) => {
                if (res.submitted) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'ZIP Code change request was submitted successfully.',
                        icon: 'success',
                        confirmButtonText: 'Close'
                    });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'There was an error when submitting your ZIP Code change request. Please try again later.',
                        icon: 'error',
                        confirmButtonText: 'Close'
                    });
                }
                toggleChangeZip();
            });
        }
    };

    const padWithPeriods = () => {
        // feels like a silly way to make get the design accurate, but this works, fill in a lot of periods toward the next text; make the next text div a white-out
        return ".......................................................................................................";
    };

    useEffect(() => {
      if (!user.zip) {
        getUserAttributesAndUpdate(user);
      }
    }, [getUserAttributesAndUpdate, user]);

    return(
      <div className="App">
        <Nav />
        <SearchBar user={user} watchedRaces={watchedRaces} />
        {/* 
            Essentially how it's laid out: 
            Nav bar (above)
            search bar and watched races (above)
            RaceSummariesSection with "Your Watched Races" within it
            RaceSummariesSection with "Your Uploads" in it, and have as many of those rows going down as there are uploads for different zip codes
            Finally, an info summary section, featuring basic info, sign out button, change password button, and change zip button (trigger admin request doc))
                My Account (sign out button)
                Email
                ZIP Code
                State (???)
                Mailers Uploaded (count)
                Races Watched (count)
                change password (button; updatePassReq(newPass) // validate pass with two, matching password fields, don't submit if they don't match, etc.)
                change zip (button; submits admin request and then some sort of alert (sweet alert?))
        */}
        <div className="container full-section watched">
          <div className="row">
            <div className="col-3 trending-info">
              <div className="row">
                <div className="no-pad-left">
                  <span className="section-headline">Your Watched Races</span>
                </div>
              </div>
              <div className="row">{currentZip}</div>
            </div>
            <RaceSummariesSection user={user} raceIds={trendingIDs} location={currentZip} upgraded={userUpgraded} lockedSummary={false} />
          </div>
        </div>
        {/* uploaded mailers, map repeats based on different zip codes uploaded to; will be tricky; follow the above div container, but add pagination */}
        <div className="container account-info">
            <div className="row">
                <div className="section-header">
                    <span>My Account</span> <button onClick={signOut}>SIGN OUT</button>
                </div>
            </div>
            <div className="row">
                <div className="col-4 left-content fill-gap bold">Email{padWithPeriods()}</div>
                <div className="col-8 left-content white-out">{user.email}</div>
            </div>
            <div className="row">
                <div className="col-4 left-content fill-gap bold">ZIP Code{padWithPeriods()}</div>
                <div className="col-8 left-content white-out">{currentZip}</div>
            </div>
            <div className="row">
                <div className="col-4 left-content fill-gap bold">State{padWithPeriods()}</div>
                <div className="col-8 left-content white-out">{user.state}</div>
            </div>
            <div className="row">
                <div className="col-4 left-content fill-gap bold">Mailers Uploaded{padWithPeriods()}</div>
                <div className="col-8 left-content white-out">{/* get this info to put here */}</div>
            </div>
            <div className="row">
                <div className="col-4 left-content fill-gap bold">Races Watched{padWithPeriods()}</div>
                <div className="col-8 left-content white-out">{watchedRaces.length}</div>
            </div>
            <div className="row">
                <button onClick={toggleChangePassword}>CHANGE PASSWORD</button>
            </div>
            {(changePassOpen) &&
                <div className="row change-pass-form">
                    <button onClick={toggleChangePassword}>CANCEL</button>
                    <input id="pass" onChange={handleChangePass} type="password" placeholder="New Password" required />
                    <input id="pass-confirm" onChange={handleChangePassConf} type="password" placeholder="New Password Confirm" required />
                    <button onClick={submitChangePass}>Submit</button>
                    <p className="pass-error">{passError}</p>
                </div>
            }
            <div className="row">
                <button onClick={toggleChangeZip}>CHANGE ZIP</button>
            </div>
            {(changeZipOpen) &&
                <div className="row change-zip-form">
                    <button onClick={toggleChangeZip}>CANCEL</button>
                    <p>Current Zip: {currentZip}</p>
                    <input id="zip" onChange={handleChangeZip} type="text" placeholder="New ZIP Code" required />
                    <button onClick={submitChangeZip}>Submit</button>
                    <p className="zip-error">{zipError}</p>
                </div>
            }
        </div>
        <Footer />
      </div>
    )
};

export default Profile;