// import { ChangeEvent, FormEvent, useState } from 'react';
// import { signInUser } from '../firebase/firebase';
// import { useNavigate } from 'react-router-dom';
import '.././App.css';
import Nav from '../components/Nav';
import DocUpload from '../components/DocUpload';
import Footer from '../components/Footer';
import HowToUpload from '../components/HowToUpload';
import UserModel from '../models/UserModel';

type UploadProps = {
    user: UserModel;
}

const Upload = (props: UploadProps) => {
    const { user } = props;

    return(
      <div className="App">
        <Nav />
        <div className="container grid-display">
          <h2 className="left-justify page-head">Upload Mailers:</h2>
          <DocUpload user={user} />
        </div>
        <HowToUpload />
        <Footer />
      </div>
    )
};

export default Upload;