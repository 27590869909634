import { Link } from 'react-router-dom';
 
const HelpBanner = () => {
    const linkToLearnMore = '/404';
 
    return (
        <div className="container full-section help-banner">
            <div className="row full-height-in-div">
                <div className="col-md-1"></div>
                <div className="col-10 col-md-3 upload-reminder hero-font">
                    You can HELP by uploading as well...
                </div>
                <div className="col-12 col-md-7 validation-reminder">
                    <div className="row">
                        <h4>By validating your account & lorum ipsum</h4>
                    </div>
                    <div className="row">
                        <p>Lorem ipsum dolor sit amet veritatis sunt quo Quis voluptatibus qui nesciunt aliquam. Ut imp.Lorem ipsum dolor sit amet. Miutb nt aliquam. Ut imp.Lorem ipsum dolor sit amet. Ab veritatis sunt quo Quis voluptatibus qui nesciunt aliquam. Ut imp.</p>
                    </div>
                    <div className="row">
                        <div className="btn btn-dark horizontally-center"><Link to={linkToLearnMore}>LEARN MORE</Link></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
 
export default HelpBanner;