
import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  updatePassword,
  NextOrObserver,
  User,
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { 
  getFirestore,
  doc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  query,
  where,
  DocumentData,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { getFirebaseConfig } from './firebase-config';
import Swal from 'sweetalert2';

const app = initializeApp(getFirebaseConfig());
const auth = getAuth(app);
export const storage = getStorage(app);
export const database = getDatabase(app);
const db = getFirestore(app);

export const signInUser = async (
  email: string, 
  password: string
) => {
  if (!email && !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const registerUser = async(email: string, password: string) => {
  if (!email && !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const userStateListener = (callback:NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback)
};

export const SignOutUser = async () => {
  await signOut(auth);
};

// Forgotten/Reset Password functions
export const passwordReset = async(email: string) => {
  return await sendPasswordResetEmail(auth, email);
};

export const confirmPassReset = async(oobCode: string, newPass: string) => {
  if (!oobCode && !newPass) return;

  return await confirmPasswordReset(auth, oobCode, newPass);
};

// Update Password functionality
export const updatePassReq = async(newPass: string) => {
  const user = auth.currentUser;
  
  if (user) {
    updatePassword(user, newPass).then(() => {
      // Update successful.
      Swal.fire({
        title: 'Success!',
        text: 'Your password change was submitted successfully',
        icon: 'success',
        confirmButtonText: 'Close'
      });
    }).catch((error) => {
      // An error ocurred
      Swal.fire({
        title: 'Error!',
        text: `Your password change did not go through: ${error}`,
        icon: 'error',
        confirmButtonText: 'Close'
      });
    });
  }
};

// admin requests
// this will go to a general firestore db document collection for admins to check and go through
export const submitAdminRequest = async(request: string, userEmail: string, currentValue: string, requestedValue: string, extraInfo?: string) => {
  try {
    const newReqRef =  await addDoc(collection(db, "adminRequests"), {request, userEmail, currentValue, requestedValue, extraInfo});
    // this method will auto-generate an ID
    return { submitted: true, message: "Request submitted successfully" };
  } catch (e) {
    console.error("Error adding admin request document: ", e);
    return { submitted: false, message: "There was an error with your request" };
  }
}

// user docs
export const addUserToDB = async(id: string, zip: string, state: string, email: string, emailUpdates: boolean, watchedRaces: string[]) => {
  try {
    // user doc format:
    // const docRef = await addDoc(collection(db, "users", id), {
    //   uid: id,
    //   zip: zip,
    //   email: email, //string
    //   emailUpdates: emailUpdates, //boolean
    //   watchedRaces: watchedRaces, // array of watched race ID strings
    // });

    // defaults on sign up:
    const upgraded = false;
    const admin = false;
    const racesUploaded: string[] = [];
    await setDoc(doc(db, "users", id), {id, zip, state, upgraded, email, emailUpdates, watchedRaces, admin, racesUploaded});
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export const getUserDoc = async(id: string) => {
  const docRef = doc(db, "users", id);
  const docSnap = await getDoc(docRef);
  let userDoc;

  if (docSnap.exists()) {
    userDoc = docSnap.data();
  } else {
    console.log("no user by that ID, I think");
  }

  if (userDoc) {
    return userDoc;
  } else {
    return {};
  }
}

export const getUserZip = async(id: string) => {
  const docRef = doc(db, "users", id); // get the user's document by their id
  const docSnap = await getDoc(docRef);
  let userDoc;
  
  if (docSnap.exists()) {
    userDoc = docSnap.data();
  } else {
    console.log("no user by that id, I think");
  }
  
  if (userDoc) {
    return userDoc.zip;
  } else {
    return "";
  }
}

export const getUsersWatchedRaces = async(id: string) => {
  const docRef = doc(db, "users", id);
  const docSnap = await getDoc(docRef);
  let userDoc;

  if (docSnap.exists()) {
    userDoc = docSnap.data();
  } else {
    console.log("no user by that id, I think");
  }

  if (userDoc) {
    return userDoc.watchedRaces as string[];
  } else {
    return [];
  }
}

export const watchRace = async(id: string, race: string) => {
  const docRef = doc(db, "users", id);
  
  await updateDoc(docRef, {
    watchedRaces: arrayUnion(race)
  });
}

export const unwatchRace = async(id: string, race: string) => {
  const docRef = doc(db, "users", id);

  await updateDoc(docRef, {
    watchedRaces: arrayRemove(race)
  });
}

// specific upload info for user's data, doesn't need to know their own ID or whether it's been approved, I think
type UploadInfoForUser = {
  url: string,
  candidate: string,
  source: string,
  nonPolitician: boolean,
  userZip: string,
}

export const uploadAdded = async(id: string, race: UploadInfoForUser) => {
  const docRef = doc(db, "users", id);

  await updateDoc(docRef, {
    racesUploaded: arrayUnion(race)
  });
}

export const getUserStatus = async(id: string) => {
  const docRef = doc(db, "users", id); // get the user's document by their id
  const docSnap = await getDoc(docRef);
  let userDoc;
  
  if (docSnap.exists()) {
    userDoc = docSnap.data();
  } else {
    console.log("no user by that id, I think");
  }
  
  if (userDoc) {
    return userDoc.upgraded;
  } else {
    return false;
  }
}

type UploadInfo = {
  url: string,
  candidate: string,
  source: string,
  nonPolitician: boolean,
  approved: boolean,
  user: string,
  userZip: string,
}

// docs with information for specific uploads, arranged by race ID
export const addRaceUploadInfoToDB = async(id: string, uploads: UploadInfo[]) => {
  // use initial race ID and upload information to create a document for that race to pull from and organize race uploads
  // race uploads doc format:
    // const docRef = await addDoc(collection(db, "users", id), {
    //   id: id, // race id string
    //   uploads: uploads, // array of individual race uploads, as typed above
    // });
  try {
    await setDoc(doc(db, "raceUploads", id), {id, uploads});
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export const updateRaceUploadInfo = async(id: string, upload: UploadInfo) => {
  const docRef = doc(db, "raceUploads", id);
  
  await updateDoc(docRef, {
    uploads: arrayUnion(upload)
  });
}

export const getRaceUploadInfo = async(id: string) => {
  const docRef = doc(db, "raceUploads", id);
  const docSnap = await getDoc(docRef);
  let uploadsDoc;

  if (docSnap.exists()) {
    uploadsDoc = docSnap.data();
  } else {
    console.log("no upload doc for that race ID, I think");
  }
  
  if (uploadsDoc) {
    return uploadsDoc;
  } else {
    return {};
  };
}

// race docs; not currently really using this, but informative as a potential future update or just for firestore reference
export const addRaceToDB = async(year: string, location: string, cand1: string, cand2: string, uploads: [string]) => {
  try {
    // race doc format:
    // const docRef = await addDoc(collection(db, "races"), {
    //   id: string (generated in document creation and then saved on an update),
    //   year: string,
    //   location: string, // state/zip/national
    //   cand1: string,
    //   cand2: string,
    //   uploads: [string //of IDs]
    // });
    const newRaceRef =  await addDoc(collection(db, "races"), {year, location, cand1, cand2, uploads});
    await updateDoc(newRaceRef, {
      id: newRaceRef.id,
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export const getRaceDoc = async(id: string) => {
  const docRef = doc(db, "races", id); // get the race document by id
  const docSnap = await getDoc(docRef);
  let raceDoc;
  
  if (docSnap.exists()) {
    raceDoc = docSnap.data();
  } else {
    console.log("no user by that id, I think");
  }
  
  if (raceDoc) {
    return raceDoc;
  } else {
    return "";
  }
}

export const getRaceDocsByLocation = async(location: string) => {
  const raceQuery = query(collection(db, "races"), where("location", "==", location));
  const querySnapshot = await getDocs(raceQuery);
  let races: DocumentData[] = [];

  querySnapshot.forEach((doc) => {
    races.push(doc.data());
  });

  return races;
}