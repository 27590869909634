import { useState, ChangeEvent } from "react";
import { useNavigate } from 'react-router-dom';
import { 
    storage,
    addRaceUploadInfoToDB,
    updateRaceUploadInfo,
    getRaceUploadInfo,
    uploadAdded,
    submitAdminRequest,
 } from '../firebase/firebase';
import { 
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from 'firebase/storage';
import Swal from 'sweetalert2';
import raceData from '../data/raceData';
import UserModel from '../models/UserModel';

type DocUploadProps = {
    user: UserModel;
}

const emptyFileArray: Array<File> = [];
const emptyFileSrcArray: string[] = [];

const DocUpload = (props: DocUploadProps) => {
    const navigate = useNavigate();
    const { user } = props;
    const [files, setFiles] = useState(emptyFileArray);
    const [fileSrcs, setFileSrcs] = useState(emptyFileSrcArray);
    const [percent, setPercent] = useState(0);
    const [raceId, setRaceId] = useState('');
    const [uploadCandidate, setUploadCandidate] = useState(''); // setting as a string; use lastName as the selection value (object is not assignable as a val and this is simpler)
    const [uploadSource, setUploadSource] = useState('');
    const [nonPolitician, setNonPolitician] = useState(false);
    const [cannotUpload, setCannotUpload] = useState(true);
    const [selectedImg, setSelectedImg] = useState('');
    const [selectedImgIndex, setSelectedImgIndex] = useState(0);
    const [uploadDone, setUploadDone] = useState(false);
    const [previewPg, setPreviewPg] = useState(0);
    const userZip = user.zip;
    const allRaceIDs = ['1', '2', '3', '4', '5']; // get rid of later, after the data is being established in a more scalable way

    const getRacesInZip = (zip: string) => { // move this to a separate, exported helper function? then can use it multiple places, including homepage local sections
        const races = [];
        for (let i = 0; i < allRaceIDs.length; i++) {
            if (raceData[i] && raceData[i].zips && raceData[i].zips.includes(zip)) {
                races.push(raceData[i]);
            }
        }
        return races;
    };

    const racesInZip = getRacesInZip(userZip);

    const raceSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const targetValue = e.currentTarget.value;
        const selectedRace = raceData[targetValue];
        setRaceId(selectedRace.id);
    };

    const selectImg = (index: number) => {
        const newImgSrc = fileSrcs[index];
        setSelectedImg(newImgSrc);
        setSelectedImgIndex(index);
    };

    const getRaceSelectOptions = () => {
        if (racesInZip && racesInZip.length > 0) {
            const selectOptions = [<option key={0} id="race-option-0" disabled selected>Assign race</option>];
            for (let i = 0; i < racesInZip.length; i++) {
                const thisRace = racesInZip[i];
                selectOptions.push(<option key={i + 1} id={`race-option-${i + 1}`} value={thisRace.id}>{thisRace.race}</option>)
            }
            return selectOptions;
        } else {
            return (<option selected>No races in your ZIP!</option>)
        }
    };

    const positionSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const positionString = e.currentTarget.value;
        setUploadCandidate(positionString);
    };

    const buildCandidateSelect = () => {
        if (!raceId) {
            return <option selected disabled>Must select a race to choose here</option>;
        } else {
            const appropriateRaceData = raceData[raceId];
            
            if (appropriateRaceData.candidates.length < 1) {
                return <option selected disabled>Race has no candidates or options</option>
            } else {
                const selectOptions = [<option key={0} id="position-option-0" disabled selected value="no-selection">Assign position</option>];
                for (let i = 0; i < appropriateRaceData.candidates.length; i++) {
                    selectOptions.push(<option key={i + 1} id={`position-option-${i + 1}`} value={appropriateRaceData.candidates[i].lastName}>{`${appropriateRaceData.candidates[i].firstName} ${appropriateRaceData.candidates[i].lastName}${(appropriateRaceData.candidates[i].incumbent)?' (I)':''}`}</option>)
                }
                return selectOptions;
            }
        }
    };

    // Handles input change event and updates state
    const handleChange = (event:any) => {
        const currentFiles = [...files];
        for (let i = 0; i < event.target.files.length; i++) {
            currentFiles.push(event.target.files[i]);
        }
        setFiles(currentFiles);
        const fileUrls = [];
        for (let i = 0; i < event.target.files.length; i++) {
            fileUrls.push(URL.createObjectURL(event.target.files[i]));
        }
        setFileSrcs(fileUrls);
        setSelectedImg(fileUrls[0]);
        setSelectedImgIndex(0);
        setCannotUpload(false);
    };

    const addAnotherFile = (event:any) => {
        const currentFiles = [...files];
        for (let i = 0; i < event.target.files.length; i++) {
            currentFiles.push(event.target.files[i]);
        }
        setFiles(currentFiles);
        const fileUrls = fileSrcs;
        for (let i = 0; i < event.target.files.length; i++) {
            fileUrls.push(URL.createObjectURL(event.target.files[i]));
        }
        setFileSrcs(fileUrls);
    };

    const increasePreviews = () => {
        // page number should basically equal the initial index
        const page = previewPg + 3;
        setPreviewPg(page);
    };

    const decreasePreviews = () => {
        // page number should basically equal the initial index
        const page = previewPg - 3;
        setPreviewPg(page);
    };

    const handleDeleteUpload = () => {
        // function to remove an upload by hitting the delete button, if someone decides they didn't want to upload that image or it was a mistake
        const index = selectedImgIndex;
        const currentFiles = [...files];
        const currentFileSrcs = [...fileSrcs];
        currentFiles.splice(index, 1);
        currentFileSrcs.splice(index, 1);
        const currentFileCount = currentFiles.length;
        if (currentFileCount > 0) {
            const newIndex = currentFileCount - 1;
            setSelectedImg(currentFileSrcs[newIndex]);
            setSelectedImgIndex((newIndex))
        } else {
            setSelectedImg('');
            setSelectedImgIndex(0);
        }
        if (currentFileCount <= 3) {
            setPreviewPg(0);
        }
        setFiles(currentFiles);
        setFileSrcs(currentFileSrcs);
    };

    const handleNonPoliticianChange = (event: any) => {
        const isChecked = event.target.checked;
        setNonPolitician(isChecked);
    };

    const handleRadioOptionChange = (event: any) => {
        const radioValue = event.target.value;
        setUploadSource(radioValue);
    };

    const formValid = () => {
        if (!files || files.length < 1) {
            setCannotUpload(true);
            return false;
        } else if (!raceId) {
            setCannotUpload(true);
            return false;
        } else if (!uploadCandidate || !uploadSource) {
            setCannotUpload(true);
            return false;
        } else {
            setCannotUpload(false);
            return true;
        }
    };

    const noUpload = () => {
        if (files.length < 1) {
            Swal.fire({
                title: 'Error!',
                text: 'Please select at least one file to upload!',
                icon: 'error',
                confirmButtonText: 'Close',
                didClose: () => {
                    // for error, reset state so a new attempt can be made
                    setCannotUpload(false);
                }
            });
        } else if (!raceId) {
            Swal.fire({
                title: 'Error!',
                text: 'Error: must select a race to which the mailer is uploaded!',
                icon: 'error',
                confirmButtonText: 'Close',
                didClose: () => {
                    // for error, reset state so a new attempt can be made
                    setCannotUpload(false);
                }
            });
        } else if (!uploadCandidate || !uploadSource) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill out the information form for the race your mailer represents!',
                icon: 'error',
                confirmButtonText: 'Close',
                didClose: () => {
                    // for error, reset state so a new attempt can be made
                    setCannotUpload(false);
                }
            });
        }
        /* if none of these are the case, we then want to do nothing because it's only hitting this if publish has already been pushed and upload has started
           this should prevent the multiple upload error */
    };

    const handleUpload = () => {
        /* basic idea currently:
           1. should be unable to get to this function from the start
           2. after adding file(s), this should then come open
           3. we check the validity, and make sure that this wasn't reached in error
             a. if it's not valid, it will check what's not valid and fire an error sweet alert through the noUpload function
             b. if it is valid, it will go ahead with the upload process
        */
        if (formValid()) {
            setCannotUpload(true); // set it so the upload/Publish button will disappear and you can't hit it again
            for (let i = 0; i < files.length; i++) {
                const storageRef = ref(storage, `/files/${raceId}/${files[i].name}`);
                const uploadTask = uploadBytesResumable(storageRef, files[i]);
    
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        let percent; // because we can't know the number of bytes if there's more than one file uploaded, set percent in a different way
                        if (files.length > 1) {
                            // if multiple files, get percentage by files done divided by total files
                            percent = Math.round(
                                ((i + 1) / files.length) * 100
                            )
                        } else {
                            // only one file, get percentage by bytes
                            percent = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );
                        }
            
                        // update progress
                        setPercent(percent);
                    },
                    (err) => {
                        Swal.fire({
                            title: 'Error!',
                            text: 'There was an error with your upload! Please try again.',
                            icon: 'error',
                            confirmButtonText: 'Close',
                            didClose: () => {
                                // for error, reset state so a new attempt can be made
                                setCannotUpload(false);
                            }
                        });
                        console.log(err);
                    },
                    () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            const currentUpload = {
                                url: url,
                                candidate: uploadCandidate,
                                source: uploadSource,
                                nonPolitician: nonPolitician,
                                approved: false,
                                user: user.id,
                                userZip: user.zip,
                            };
    
                            // different upload object for the user's information, because they don't need to know their own user ID or approval status, basically
                            // also, leaving off approval status won't require admin to update approval status in two locations while it's manual
                            const uploadForUser = {
                                url: url,
                                candidate: uploadCandidate,
                                source: uploadSource,
                                nonPolitician: nonPolitician,
                                userZip: user.zip,
                            };
                            
                            getRaceUploadInfo(raceId).then((doc) => {
                                /* feels like there should be a better way to do this, but first way I found to get the upload info back from firestore
                                to properly deal with the upload data; try a standalone async get function, maybe?
                                */
                                // save some information for the admin request:
                                const req = "approve new upload";
                                const email = user.email;
                                const currentValue = "false";
                                const requestedValue = "true";
                                const extraInfo = `Race ID: ${raceId}; and image URL: ${currentUpload.url}`;
    
                                if (Object.entries(doc).length === 0) {
                                    // only hits if the object is empty, so add new document for this race
                                    addRaceUploadInfoToDB(raceId, [currentUpload]);
                                    uploadAdded(user.id, uploadForUser);
                                } else {
                                    // document already exists, so get it by the raceId, and append the current upload to it
                                    updateRaceUploadInfo(raceId, currentUpload);
                                    uploadAdded(user.id, uploadForUser);
                                }
                                submitAdminRequest(req, email, currentValue, requestedValue, extraInfo);
                            });
                            if (i + 1 === files.length) {
                                setUploadDone(true);
                            }
                        });
                    }
                );
            }
            if (uploadDone) {
                // when uploadDone has been changed to true (after uploading the last file), then fire the success Sweet Alert
                Swal.fire({
                    title: 'Success!',
                    text: 'Your upload has completed! Please allow time for it to go through the approval process.',
                    icon: 'success',
                    confirmButtonText: 'Close',
                    didClose: () => { navigate(`/race/${raceId}`)} // redirect to the race that was uploaded to after success
                });
            }
        } else {
            // fire error Sweet Alert if it's not valid
            noUpload();
        }
    };
    /* TO DO:
        1. drag and drop functionality?
        2. troubleshoot and work out multiple files in an upload
        3. hide address functionality (on hold? still in discussion?)
    */
 
    return (
        <div className="container">
            <div className="row">
                {fileSrcs && fileSrcs.length >= 1 ?
                (<div className="col-12 col-md-8 upload-preview">
                    <img src={selectedImg} className="upload-img-preview" alt="full-sized upload preview" />
                </div>):
                <div className="col-12 col-md-8 drop-ctr grid-display align-items-center justify-items-center">
                    <div className="file-drop align-self-center align-items-center">
                        <div className="container drop-elements align-items-center justify-items-center">
                            <input id="fileUpload" type="file" accept="image/*" onChange={handleChange} multiple />
                            <label className="file-upload-btn justify-content-center" htmlFor="fileUpload">UPLOAD</label>
                            {/* <div className="row bold centered justify-content-center">or</div> */}
                            <div className="row centered justify-content-center">Disclaimer: Please take your images or edit them so that Personal Identifying Information is not visible. The upload approval process can take up to 24 hours.</div>
                            <div className="row justify-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className="col-12 col-md-3 drop-details justify-items-start align-content-start">
                    <div className="row">
                        <div className="col-10 bold">Details</div>
                        <div className="col-2 tooltip-trigger">
                            <span id="detail-tooltip" className="tooltip-text bottom">Select an image first, then click to delete</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill tooltip-trigger" viewBox="0 0 16 16" onClick={handleDeleteUpload}>
                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                            </svg>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 zip underline">{userZip}</div>
                        <div className="col-7"></div>
                        <div className="col-2 tooltip-trigger">
                            <span id="zip-tooltip" className="tooltip-text bottom">To change your ZIP, go to the profile page and submit a request!</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                            </svg>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <select className="form-select" id="race-select" onChange={(e) => raceSelectChange(e)} aria-label="Race/campaign selection">
                                {getRaceSelectOptions()}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {(!raceId) ?
                                <select className="form-select" id="race-option-select" onChange={(e) => positionSelectChange(e)} aria-label="Race candidate or position selection" disabled>
                                    {buildCandidateSelect()}
                                </select> :
                                <select className="form-select" id="race-option-select" onChange={(e) => positionSelectChange(e)} aria-label="Race candidate or position selection">
                                    {buildCandidateSelect()}
                                </select>
                            }
                        </div>
                    </div>
                    {/* <div className="row justify-content-center">
                        <button className="upload-opt-btn">HIDE ADDRESS</button>
                    </div> */}
                    <div className="row">
                        <div className="container options">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="optionsRadio" id="optionsRadioDirect" value="direct" onChange={handleRadioOptionChange}></input>
                                <label className="form-check-label bold option-title" htmlFor="optionsRadioDirect">Direct Mailer</label>
                            </div>

                            <div className="row input-description">Direct Mailers are mails that directly from an established campaign</div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="optionsRadio" id="optionsRadioSponsor" value="sponsor" onChange={handleRadioOptionChange}></input>
                                <label className="form-check-label bold option-title" htmlFor="optionsRadioSponsor">Sponsor Mailer</label>
                            </div>
                            <div className="row input-description">These are mailers from supporters such as groups, committees, or PACs</div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="optionsRadio" id="optionsRadioDark" value="dark" onChange={handleRadioOptionChange}></input>
                                <label className="form-check-label bold option-title" htmlFor="optionsRadioDark">Dark</label>
                            </div>
                            <div className="row input-description">There is no markings or the markings are insufficient or misleading</div>
                        </div>
                        <div className="col-12 upload-check">
                            <div className="form-check">
                                <input className="form-check-input" onChange={handleNonPoliticianChange} type="checkbox" value="" id="nonPolitician"></input>
                                <label className="form-check-label bold option-title" htmlFor="nonPolitician">Non-Politician</label>
                            </div>
                            <div className="row input-description">This mailer is for a referendum or other political cause with no clear single person</div>
                        </div>
                        <div className="row justify-content-center">
                            {cannotUpload ? 
                            <button className="upload-opt-btn disabled" aria-disabled="true" disabled>PUBLISH{(files && files.length > 0)?` (${files.length})`:""}</button> :
                            <button className="upload-opt-btn" onClick={handleUpload} aria-disabled="false">PUBLISH{(files && files.length > 0)?` (${files.length})`:""}</button>
                            }
                            <p>{percent}% done.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row docs d-flex justify-content-start align-content-start">
                <div className="col-3 col-md-2 add-more">
                    <input id="moreFileUpload" type="file" accept="image/*" onChange={addAnotherFile} multiple />
                    <label htmlFor="moreFileUpload">
                        <div className="row add-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </div>
                        <div className="row add-text">ADD MORE</div>
                    </label>
                </div>
                {(files && files.length > 0) ? fileSrcs.map((fileSrc, index) => {
                 return (<div className={`col-3 col-md-2 doc-preview${(index < previewPg || index >= (previewPg + 3))?" hidden":""}`} onClick={() => selectImg(index)}><img src={fileSrc} className="preview-img" alt="file preview thumb"></img></div>)}) :
                 null }
                {(files && files.length > 3) &&
                    <div className="pagination-ctrl">
                        {(previewPg === 0) ?
                            <div className="prev-btn disabled" aria-disabled={true}>{"<"}</div> :
                            <div className="prev-btn" onClick={decreasePreviews}>{"<"}</div>
                        }
                        {((previewPg + 3) < (files.length)) ?
                            <div className="next-btn" onClick={increasePreviews}>{">"}</div> :
                            <div className="next-btn disabled" aria-disabled={true}>{">"}</div>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default DocUpload;