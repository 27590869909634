import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserModel from '../models/UserModel';
import raceData from '../data/raceData';
import { getRaceDoc, getRaceUploadInfo } from '../firebase/firebase';
import mailer1 from '../img/example-mailer.png';
import mailer2 from '../img/example-mailer-2.png';
import mailer3 from '../img/example-mailer-3.png';
import mailer4 from '../img/example-mailer-4.png';

type RaceSummaryProps = {
    user: UserModel;
    raceId: number;
    upgraded: boolean;
    lockedSummary: boolean;
}

/* updated race data:
type Candidate = {
    firstName: string;
    lastName: string;
    affiliation: string;
}

type RaceType = {
    [index: string]: {
        id: string;
        race: string;
        candidates: Candidate[];
        year: string;
        location: string;
        zips: string[];
        notes: string[];
        winner: string;
    }
}

A lot of things will need to be updated here based on the changed data
*/

type UploadObject = {
    url: string;
    source: string;
    candidate: string;
    nonPolitician: boolean;
}

const emptyUploadObj: UploadObject = {
    url: '',
    source: '',
    candidate: '',
    nonPolitician: false,
};
 
const RaceSummary = (props: RaceSummaryProps) => {
    const { user, raceId, upgraded, lockedSummary } = props;
    const currentRaceData = raceData[raceId];
    const [uploadCount, setUploadCount] = useState(0);
    const [chosenImg, setChosenImg] = useState(emptyUploadObj);

    const getRaceUploads = async(id: string) => {
        // param: race ID
        const uploads = await getRaceUploadInfo(id);
        if (uploads && uploads.uploads && uploads.uploads.length > 0) {
            const imgIndex = (id === "1")?1:0;
            setChosenImg(uploads.uploads[imgIndex]);
            setUploadCount(uploads.uploads.length);
        }
    };

    useEffect(() => {
        if (raceId) {
            const raceIdConvert = raceId.toString();
            getRaceUploads(raceIdConvert);
        }
    }, [raceId]);
    
    const getImage = () => {
        if (uploadCount > 0) {
            return <img src={chosenImg.url} className="mailer-thumb" alt="mailer upload" />
        } else {
            return <div className="race-image-placeholder">(No Uploads)</div>;
        }
    };
 
    return (
        <div className="container race-summary">
            {(!lockedSummary) ?
                <Link to={`/race/${raceId}`}>
                    <div className="row race-image-container">
                        <div className="race-image">{getImage()}</div>
                        {(lockedSummary && !upgraded) && <div className="locked-img"></div>}
                    </div>
                    <div className="row race-info">
                        {currentRaceData && currentRaceData.candidates.length === 2 ?
                            <div className="col-6 candidates">
                                <div className="row candidate-one">{currentRaceData?currentRaceData.candidates[0].lastName:""}</div>
                                <div className="row candidate-two">{currentRaceData?currentRaceData.candidates[1].lastName:""}</div>
                            </div> :
                            <div className="col-6 race">{currentRaceData?(currentRaceData.location + " " + currentRaceData.race):""}</div>
                        }
                        <div className="col-6 extra-race-info">
                            <div className="row race-year"><span className="bold">{currentRaceData?currentRaceData.year:""}</span></div>
                            <div className="row upload-count">({uploadCount}) uploads</div>
                        </div>
                    </div>
                </Link> :
                <div>
                    <div className="row race-image-container">
                        <div className="race-image">{getImage()}</div>
                        {(lockedSummary && !upgraded) && <div className="locked-img"></div>}
                    </div>
                    <div className="row race-info">
                        {currentRaceData && currentRaceData.candidates.length === 2 ?
                            <div className="col-6 candidates">
                                <div className="row candidate-one">{currentRaceData?currentRaceData.candidates[0].lastName:""}</div>
                                <div className="row candidate-two">{currentRaceData?currentRaceData.candidates[1].lastName:""}</div>
                            </div> :
                            <div className="col-6 race">{currentRaceData?(currentRaceData.location + " " + currentRaceData.race):""}</div>
                        }
                        <div className="col-6 extra-race-info">
                            <div className="row race-year"><span className="bold">{currentRaceData?currentRaceData.year:""}</span></div>
                            <div className="row upload-count">({uploadCount}) uploads</div>
                        </div>
                    </div>
                </div>
            }
            
        </div>
        
    );
};
 
export default RaceSummary;