import {
    ChangeEvent,
    FormEvent,
    useState,
} from "react";
import { 
    useSearchParams,
    useNavigate,
    Link,
} from "react-router-dom";
import { confirmPassReset } from "../firebase/firebase";
import '.././App.css';
import Nav from '../components/Nav';

const defaultFormFields = {
    password: '',
    confirmPassword: '',
};

const ResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { password, confirmPassword } = formFields;
    let oobCode: string | null = searchParams.get('oobCode');

    const resetFormFields = () => {
        return (
            setFormFields(defaultFormFields)
        );
    };

    const handleSubmit = async(evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        if (password !== confirmPassword) {
            setErrorMessage("You password and confirmation don't match.");
            return;
        }

        try {
            if (oobCode) {
                await confirmPassReset(oobCode, confirmPassword);
                resetFormFields();
                setSuccessMessage(true);
            } else {
                setErrorMessage("We didn't get a valid code. Please try again.");
            }
        } catch (error: any) {
            if (error.code === 'auth/invalid-action-code') {
                setErrorMessage("Invalid action code.");
            }
            setErrorMessage("There was an error with your password change request. Please try again later. If the error persists, contact support.");
        }
    };

    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = evt.target;
        setErrorMessage("");
        setFormFields({...formFields, [name]: value });
    };

    return (
        <div className="App">
            <Nav />
            <div className="container full-section">
                { successMessage ?
                <div>
                    <h2>Your Password has been changed!</h2>
                    <p>Go back to <Link to={'/login'}>login here!</Link></p>
                </div> :
                <div className="row pad-top">
                    <h2>Change Password</h2>
                    <form className="justify-items-center align-items-center" onSubmit={handleSubmit}>
                        <div className="col-5"></div>
                        <div className="col-2 pad-top">
                            <div className="row">
                                <input type="password" name="password" value={password} placeholder="Password" onChange={handleChange} required />
                            </div>
                            <div className="row pad-top">
                                <input type="password" name="confirmPassword" value={confirmPassword} placeholder="Confirm Password" onChange={handleChange} required />
                            </div>
                            <div className="row pad-top">
                                <button type="submit">Submit Password Change</button>
                            </div>
                            { errorMessage && 
                            <div className="row error-message"></div>
                            }
                        </div>
                        <div className="col-5"></div>
                    </form>
                </div>
                }
            </div>
        </div>
    );
};
 
export default ResetPassword;