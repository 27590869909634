import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getUserDoc } from '../firebase/firebase';
import '.././App.css';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import UserModel from '../models/UserModel';
import SearchBar from '../components/SearchBar';
import MapSelector from '../components/MapSelector';
import RaceSummariesSection from '../components/RaceSummariesSection';
import HelpBanner from '../components/HelpBanner';

type HomeProps = {
    user: UserModel;
    changeUser: (id: string, zip: string, email: string, state: string, upgraded: boolean) => void;
    watchedRaces: string[];
    updateWatchedRaces: (races: string[]) => void;
}

const Home = (props: HomeProps) => {
    const { user, changeUser, watchedRaces, updateWatchedRaces } = props;
    const upload = '/upload';
    const error = '/404';
    const currentZip = user.zip;
    const userUpgraded = user.upgraded;
    const nationalRaceIDs = [1, 2, 3, 4, 5, 2];
    const localRaceIDs = [1, 2, 3, 4, 5, 3];
    const localRaceIDsPart2 = [1, 2, 3, 4, 5, 1];
    const editorsPicksIDs = [1, 2, 3, 4, 5, 4];
    const archivedIDs = [1, 2, 3, 4, 5];
    const trendingIDs = [1, 3, 4, 5];

    const getUserAttributesAndUpdate = useCallback(async(user: UserModel) => {
      const userDoc = await getUserDoc(user.id);
      const zip = userDoc.zip;
      const status = userDoc.status;
      const email = userDoc.email;
      const userState = userDoc.state;
      changeUser(user.id, zip, email, userState, status);
    }, [changeUser]);

    useEffect(() => {
      if (!user.zip) {
        getUserAttributesAndUpdate(user);
      }
    }, [getUserAttributesAndUpdate, user]);

    return(
      <div className="App">
        <Nav />
        <SearchBar user={user} watchedRaces={watchedRaces} />
        <div className="container trending">
          <div className="row">
            <div className="col-sm-12 col-md-3 trending-info">
              <div className="row">
                <div className="no-pad-left">
                  <span className="section-headline">Your Current Trending</span> <span className="blue">{currentZip}</span>
                </div>
              </div>
              <div className="row">Lorem ipsum dolor sit amet. Ab ventatis sunt quo Quis voluptatibus qui nesciunt aliquam. Ut imp. Lorem ipsum dolor sit amet.</div>
              <div className="row trending-button">
                <Link className="btn" to={error}>SEE MORE</Link>
              </div>
            </div>
            <RaceSummariesSection user={user} raceIds={trendingIDs} location={currentZip} upgraded={userUpgraded} lockedSummary={false} />
          </div>
        </div>
        <MapSelector user={user} />
        <RaceSummariesSection user={user} raceIds={nationalRaceIDs} headline={"Top National Races"} location={"national"} upgraded={userUpgraded} lockedSummary={true} />
        <RaceSummariesSection user={user} raceIds={localRaceIDs} headline={"Top Local Races"} location={currentZip} upgraded={userUpgraded} lockedSummary={false} />
        <HelpBanner />
        <RaceSummariesSection user={user} raceIds={localRaceIDsPart2} headline={"Top Local Races"} location={"(continued)"} upgraded={userUpgraded} lockedSummary={false} />
        <RaceSummariesSection user={user} raceIds={editorsPicksIDs} headline={"Editor's Picks"} location={"editor"} upgraded={userUpgraded} lockedSummary={true} />
        <RaceSummariesSection user={user} raceIds={archivedIDs} headline={"Archived Races"} location={"archived"} upgraded={userUpgraded} lockedSummary={true} />
        <Footer />
      </div>
    )
};

export default Home;