import { useEffect, useContext } from 'react';
import { AuthContext } from '../context/auth-context';
import { Link, useNavigate } from 'react-router-dom';
import { getUserDoc, getUsersWatchedRaces } from '../firebase/firebase';
import raceData from '../data/raceData';
import UserModel from '../models/UserModel';
import RaceLink from './RaceLink';
 
type SearchBarProps = {
    user: UserModel;
    watchedRaces: string[];
}

const getWatchedRaces = async(id: string) => {
    const userDoc = await getUserDoc(id);
    let races = userDoc.watchedRaces.filter((race: string) => race !== '');

    return races;
};

// can build a simple search functionality now, based on the sample data in our files

const SearchBar = (props: SearchBarProps) => {
    const { user, watchedRaces } = props;
    const { signOut } = useContext(AuthContext);
    const navigate = useNavigate();
    const linkToLearnMore = '/404';
    const linkToProfile = '/profile';

    const goToProfile = () => {
        navigate(linkToProfile);
    };

    const toggleDropdown = () => {
        const userMenu = document.getElementById('user-menu');
        if (userMenu) {
            userMenu.classList.toggle("show-menu");
        }
    };

    const profileIconBtnMenu = () => {
        return (<div className="d-xs-none d-md-block dropdown">
                <button className="dropdown-toggle" type="button" onClick={goToProfile}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                </svg></button>
            </div>);
    };

    const makeRaceLinks = (raceList: string[]) => {
        if (raceList.length < 1) {
            return;
        } else {
            const list = [];
            for (let i = 0; i < raceList.length; i++) {
                let currentRace = raceData[i];
                if (currentRace) {
                    list.push(<RaceLink cand1={currentRace.candidates.length === 2?currentRace.candidates[0].lastName:''} cand2={currentRace.candidates.length === 2?currentRace.candidates[1].lastName:''} race={currentRace.race} raceId={currentRace.id} location={currentRace.location} color={"gray"} />);
                }
            }
            if (list.length > 0) {
                return <div className="row">{list}</div>
            } else {
                return;
            }
        }
    }
 
    // eventually, need functionality to make a search work, but we don't have that kind of data yet

    return (
        <div className="container full-section search-bar">
            <div className="row full-height-in-div">
                <div className="col-md-4 col-sm-6 search">
                    <input className="search-input" placeholder="Search races" />
                </div>
                <div className="col-2"></div>
                <div className="col-5 followed-races">{makeRaceLinks(watchedRaces)}</div>
                <div className="d-xs-none d-md-block col-1">{profileIconBtnMenu()}</div>
            </div>
        </div>
    );
};
 
export default SearchBar;