import { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInUser, getUserZip, getUserStatus, getUserDoc } from '../firebase/firebase';
import '.././App.css';
import Nav from '../components/Nav';
import NewsCard from '../components/NewsCard';
import ImgLogin1 from '../img/img-cor-login1.svg';
import ImgLogin2 from '../img/img-cor-login2.svg';
import ImgLogin3 from '../img/img-cor-login3.svg';

const defaultFormFields = {
    email: '',
    password: '',
};

const defaultUser = {
  name: '',
  id: '',
  zip: '',
  upgraded: false,
};

type LoginProps = {
  changeUser: (id: string, zip: string, email: string, state: string, upgraded: boolean) => void;
};

const Login = (props: LoginProps) => {
    const { changeUser } = props;
    const [formFields, setFormFields] = useState(defaultFormFields);
    const [errorMessage, setErrorMessage] = useState('');
    const { email, password } = formFields;
    const navigate = useNavigate();

    const resetFormFields = () => {
      return (
        setFormFields(defaultFormFields)
      );
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      try {
        // Send the email and password to firebase
        const userCredential = await signInUser(email, password);

        if (userCredential) {
          const user = await getUserDoc(userCredential.user.uid);
          const userZip = user.zip;
          const userUpgrade = user.upgraded;
          const email = user.email;
          const userState = user.state;
          changeUser(userCredential.user.uid, userZip, email, userState, userUpgrade);
          resetFormFields();
          navigate('/');
        }
      } catch (error:any) {
        console.log('User Sign In Failed', error.message);
        if (error.message.includes('user-not-found')) {
          setErrorMessage("User not found.");
        } else if (error.message.includes('internal-error')) {
          setErrorMessage("Internal error attempting to sign in. Please try again later.");
        } else {
          setErrorMessage("Sign in failed. Check your credentials and try again.");
        }
      }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      if (errorMessage) {
        setErrorMessage('');
      }
      setFormFields({...formFields, [name]: value })
    };

    const signupLink = '/signup';

    return(
      <div className="App">
        <Nav />
        <div className="container full-section auth-page">
          <div className="row full-height">
            <div className="col col-md-3 sign-in-div">
              <div className="signin">
                <h2 className="hero-font sign-in-headline">Sign In</h2>
                <form onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row d-flex">
                      <div className="col-1"></div>
                      <div className="col-10 grid-display">
                        <label className="left-justify headline-sm" htmlFor="email">Email</label>
                      </div>
                      <div className="col-1"></div>
                    </div>
                    <div className="row d-flex">
                      <div className="col-1"></div>
                      <div className="col-10 justify-content-start">
                        <input
                          type="email"
                          name="email"
                          value={email}
                          className="sign-in-input"
                          onChange={handleChange}
                          placeholder="example@email.com"
                          required
                        />
                      </div>
                      <div className="col-1"></div>
                    </div>
                    <div className="row d-flex">
                      <div className="col-1"></div>
                      <div className="col-10 grid-display">
                        <label className="left-justify headline-sm" htmlFor="password">Password</label>
                      </div>
                      <div className="col-1"></div>
                    </div>
                    <div className="row d-flex">
                      <div className="col-1"></div>
                      <div className="col-10 justify-content-start">
                        <input
                          type='password'
                          name='password'
                          value={password}
                          className="sign-in-input"
                          onChange={handleChange}
                          placeholder="password"
                          required
                        />
                      </div>
                      <div className="col-1"></div>
                    </div>
                    <div className="row d-flex justify-content-center small pad-top">
                      <div>Not yet a user SIGN UP <Link className="underline" to={signupLink}>here</Link></div>
                      <div>Forgot Password? <Link className="underline" to={"/forgot-password"}>RESET here</Link></div>
                    </div>
                    <div className="row d-flex">
                      <div className="col-1"></div>
                      <div className="col-10 sign-in-button-grp">
                        <input id='recaptcha' className="sign-in-button" type="submit" value="SIGN IN" />
                        { errorMessage && 
                        <div className="row sign-in-error">{errorMessage}</div> }
                      </div>
                      <div className="col-1"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-9 d-none d-md-flex  sign-in-main">
              <div className="news justify-content-center">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <h2 className='rale-font txt-bold'>Our Latest News</h2>
                    </div>
                    </div>
                      <div className="row cards justify-content-center">
                         <div className="col-md-3 news-card mx-2">
                            <NewsCard date="Jan 2024" headline="Our MVP is up and running and ready for 2024!" img={ImgLogin2} text="We've merged our branches and have been successful in creating the base application! We'll be updating and working diligently to provide a robust experience for our Alpha build." />
                      </div>
                      <div className="col-md-3 news-card mx-2">
                        <NewsCard date="Feb 2024" headline="Courant application launch is officially live!" img={ImgLogin1} text="Courant has been able to streamline the uploading process and begin working on features such as admin portal, PII sanitation, and smooth UX." />
                      </div>
                      <div className="col-md-3 news-card mx-2">
                        <NewsCard date="Mar 2024" headline="Have a suggestion? You can contact us by email" img={ImgLogin3} text="Have a suggestion or a way to improve the Courant App? Drop us a line at hello@courant.com. Sign up for our mailing list at courantapp.com to get the latest news on future releases." />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Login;