// import {useState} from "react";
import { Link } from 'react-router-dom';
import UserModel from '../models/UserModel';
import RaceSummary from './RaceSummary';
import mapImg from '../img/map-img.png'; // temporarily using an image of the eventual map; will eventually build an interactive map
// import { getRaceDocsByLocation } from '../firebase/firebase';

type MapSelectorProps = {
    user: UserModel;
}
 
const MapSelector = (props: MapSelectorProps) => {
    const { user } = props;
    const currentZip = user.zip;
    const userUpgraded = user.upgraded;
    const error = "/404";
    const totalRaces = 31;
    const otherRaces = 9;
    const currentWidth = window.screen.width;
    const raceOne = {
        cand1: "Bill",
        cand2: "Hereislongname"
    };
    const raceTwo = {
        cand1: "Haggins",
        cand2: "Tacos"
    };
    const raceThree = {
        cand1: "Bellingcat",
        cand2: "Icons"
    };
    const padWithPeriods = () => {
        if (currentWidth < 500) {
            // still, not a great solution to the design, but put fewer periods in the space between cols at lower widths so it doesn't go beyond the button
            return ".................................";
        }
        // and more periods at more full-width so it fills the space more effectively
        return "................................................";
    }
    // const currentRaces = getRaceDocsByLocation(location); // use this eventually to get races by the location and populate from there?
 
    return (
        <div className="container full-section map-selector">
            <div className="row">
                <div className="col-sm-12 col-md-3 map-race-info">
                    <div className="row map-info-headline">Select a state to see all current races</div>
                    <div className="row counteract-map-padding">
                        <p>Lorem ipsum dolor sit amet. Ab veritatis sunt quo Quis voluptatibus qui nesciunt aliquam. Ut imp. Lorem ipsum dolor sit amet.</p>
                    </div>
                    <div className="row">
                        <Link className="btn btn-light" to={error}>SEE ALL</Link>
                    </div>
                    <div className="row map-race-headline">Races: {totalRaces} Other(s): {otherRaces}</div>
                    <div className="row counteract-map-padding">
                        <p>Lorem ipsum dolor sit amet. Ab veritatis sunt quo Quis voluptatibus qui nesciunt aliquam. Ut imp.</p>
                    </div>
                    <div className="row counteract-map-padding">
                        <div className="col-6 race-breakdown">The Breakdown --</div>
                        <div className="col-6">(most popular races)</div>
                    </div>
                    <div className="row">
                        <div className="col-9 left-content fill-gap">{raceOne.cand1} v {raceOne.cand2}{padWithPeriods()}</div>
                        <div className="col-3 left-content f8-out"><Link className="btn btn-light" to={error}>GO</Link></div>
                    </div>
                    <div className="row">
                        <div className="col-9 left-content fill-gap">{raceTwo.cand1} v {raceTwo.cand2}{padWithPeriods()}</div>
                        <div className="col-3 left-content f8-out"><Link className="btn btn-light" to={error}>GO</Link></div>
                    </div>
                    <div className="row">
                        <div className="col-9 left-content fill-gap">{raceThree.cand1} v {raceThree.cand2}{padWithPeriods()}</div>
                        <div className="col-3 left-content f8-out"><Link className="btn btn-light" to={error}>GO</Link></div>
                    </div>
                    <div className="row">
                        <Link className="btn btn-light" to={error}>SEE MORE</Link>
                    </div>
                </div>
                <div className="d-sm-none d-md-block col-md-8 map-container">
                    <img src={mapImg} className="map-image" alt="map" />
                </div>
            </div>
        </div>
    );
};
 
export default MapSelector;